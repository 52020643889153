import React, {createContext, useState, useEffect} from "react";
import {auth, functions} from "../Firebase";
import {adminInfo, checkIfUserIsDisabled} from "../services/user-services";
import {useParams} from "react-router";
import {showConfirmDialog, spinner} from "../App";

export const UserContext = createContext({
    user: null,
    isThirdParty: false,
    admin: null,
    isSuperAdmin: false,
});

const UserProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [isThirdParty, setIsThirdParty] = useState(false);
    const [admin, setAdmin] = useState(null);
    const [isDisable, setDisable] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const getUserInfo = () => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                spinner(true);
                user
                    .getIdTokenResult()
                    .then(async (tokenResult) => {
                        const admin = tokenResult.claims.admin;
                        console.log("admin", admin);
                        const isThirdParty = tokenResult.claims.isThirdPartyLogistic;
                        console.log("isThirdParty", isThirdParty);
                        const isSuperAdmin = tokenResult.claims.superAdmin;
                        try {
                            if (admin || isSuperAdmin) {
                                setUser(user);
                                setIsThirdParty(isThirdParty);
                                setAdmin(user.uid);
                                setIsSuperAdmin(isSuperAdmin);
                            } else {
                                setUser(null);
                                return auth.signOut();
                            }
                        } catch (error) {
                            console.log(error, "error");
                            if (error.code === "auth/user-disabled") {
                                alert(
                                    "Your account has been disabled. Please contact support."
                                );
                                setUser(null);

                                return auth.signOut();
                            } else {
                                console.error("Error fetching admin info:", error.message);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error("Error getting token result:", error.message);
                    })
                    .finally(() => {
                        spinner(false);
                    });
            } else {
                setUser(null);
                setIsThirdParty(false);
            }
        });
    };

    const isUserDisabled = () => {
        var disableUserAccount = functions.httpsCallable("isUserDisable");
        let data = {
            userId: user?.uid,
        };
        disableUserAccount(data)
            .then((res) => {
                console.log("User disable status:", res.data);
                if (res.data) {
                    alert("You are banned from Trippie Air. Please contact support.");
                    auth.signOut();
                }
                setDisable(res.data);
            })
            .catch((error) => {
                console.error("Error checking user disable status:", error.message);
                showConfirmDialog(true, error.message);
            });
    };

    useEffect(() => {
        getUserInfo();
        return () => {
            auth.signOut();
        };
    }, []);

    useEffect(() => {
        let timer;
        if (user?.uid && isThirdParty) {
            timer = setInterval(() => {
                isUserDisabled();
            }, 5000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [user?.uid]);

    return (
        <UserContext.Provider
            value={{
                user,
                isThirdParty,
                admin,
                isDisable,
                isSuperAdmin,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
