import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {auth, firestore, functions} from "../../Firebase";
import {showConfirmDialog, spinner} from "../../App";
import {Helmet} from "react-helmet";
import Select from "react-select";
import {driveList} from "../../services/user-services";
import {formattedDeliveryAmount, getStatus, haveValue, isCancelled,} from "../../utils/helper";
import Spinner from "../ModalComponents/Spinner";
import {FaTruckPickup} from "react-icons/fa";
import {TbTruckDelivery} from "react-icons/tb";
import {
  REQUEST_ACCEPTED,
  REQUEST_ASSIGNED,
  REQUEST_CANCELLED,
  REQUEST_COMPLETE,
  REQUEST_DELIVERED,
  REQUEST_FINALISED,
  REQUEST_PICKED_UP,
} from "../../constants";
import {DeliveryCancelDialog} from "../Models/CancelModel";

const DeliveryDetails = () => {
  const { deliveryId, userId } = useParams();
  const navigate = useNavigate();
  let listener = null;
  const [selectedOption, setSelectedOption] = useState(null);
  const [driveOptions, setDriversOptions] = useState([]);
  const [deliveryData, setDeliveryData] = useState(null);
  const [travelerId, setTravelerId] = useState(null);
  const [history, setHistory] = useState([]);
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  function fetchData() {
    if (listener) {
      return listener();
    }

    if (deliveryId && userId) {
      spinner(true);
      listener = firestore
        .collection(`delivery_requests/${userId}/requests_stored`)
        .doc(deliveryId)
        .onSnapshot((docSnap) => {
          if (docSnap.exists) {
            spinner(false);
            setDeliveryData(docSnap.data());
          }
        });
    }
  }

  async function fetchHistory() {
    try {
      const deliveryHistoryRef = firestore
        .collection("delivery_history")
        .where("deliveryId", "==", deliveryId)
        .orderBy("timestamp", "desc");

      deliveryHistoryRef.onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setHistory(data);
      });
    } catch (error) {
      console.error("Error fetching delivery history:", error);
    }
  }

  //** assign driver handler change */
  const assignDriverHandler = async () => {
    try {
      if (haveValue(selectedOption)) {
        const assignDeliveryPartner = functions.httpsCallable(
          "assignDeliveryPartner"
        );
        let data = {
          travellerId: selectedOption.value,
          requestId: deliveryId,
          senderId: userId,
        };
        spinner(true);
        try {
          const res = await assignDeliveryPartner({ tripModel: data });
          spinner(false);
          setSelectedOption(null);
          showConfirmDialog(false, "Delivery Agent Assigned successfully");
        } catch (error) {
          spinner(false);
          console.error("Error while assigning driver", error);
          showConfirmDialog(true, error.message);
        }
      }
    } catch (error) {
      console.error("Error while assigning driver", error);
      showConfirmDialog(true, error.message);
    }
  };

  const switchDriverHandler = async () => {
    if (haveValue(selectedOption)) {
      const switchDriver = functions.httpsCallable("switchDriver");
      let data = {
        travellerId: selectedOption.value,
        requestId: deliveryId,
        senderId: userId,
      };
      spinner(true);

      switchDriver(data)
        .then(() => {
          showConfirmDialog(false, "Delivery Agent Assigned successfully");
          setSelectedOption(null);
        })
        .catch((error) => {
          console.error("Error while switching driver", error);
          showConfirmDialog(true, error.message);
        })
        .finally(() => {
          spinner(false);
        });
    }
  };

  const cancelRequest = async (reason) => {
    if (!userId || !deliveryId) {
      showConfirmDialog(true, "Missing required information for cancellation");
      return;
    }

    const data = {
      travellerId: travelerId,
      requestId: `${deliveryId}_${deliveryData?.tripId}`,
      senderId: userId,
      cancelledBy: "admin",
      storeId: deliveryId,
      cancellationReason: reason,
    };

    try {
      spinner(true);
      const cancelDeliveryRequest = functions.httpsCallable(
        "cancelDeliveryRequest"
      );
      await cancelDeliveryRequest(data);
      showConfirmDialog(false, "Delivery cancelled successfully");
    } catch (error) {
      console.error("Error while cancelling delivery:", error);
      showConfirmDialog(
        true,
        error.message || "An error occurred while cancelling the delivery"
      );
    } finally {
      spinner(false);
    }
  };

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (!user && listener) {
        listener();
      }
    });

    fetchData();
    if (deliveryId) {
      fetchHistory();
    }
    return () => {
      if (listener) {
        listener();
      }
      unsubscribeAuth();
    };
  }, [deliveryId, userId]);

  useEffect(() => {
    let notInclude = [];
    if (deliveryData?.senderId) {
      notInclude.push(deliveryData.senderId);
    }
    if (
      Array.isArray(deliveryData?.history) &&
      deliveryData?.history?.length > 0
    ) {
      const lastAssignedDriver = deliveryData.history.at(-1);
      notInclude.push(lastAssignedDriver.travellerId);
      setTravelerId(lastAssignedDriver.travellerId);
    }

    driveList(notInclude)
      .then((list) => {
        const mapDriveOptions = list.map((driver) => ({
          value: driver?.id,
          label: `${driver?.firstName} ${driver?.surname}`,
        }));
        setDriversOptions(mapDriveOptions);
      })
      .catch((error) => console.log("error while fetch driver", error));
  }, [deliveryData]);

  const showSelectBoxStatuses = [
    REQUEST_ASSIGNED,
    REQUEST_ACCEPTED,
    REQUEST_PICKED_UP,
  ];

  console.log("deliveryData", deliveryData);
  return (
    <div className="trip-details-background" id="loadTripDetails">
      <Spinner />
      <DeliveryCancelDialog
        onConfirm={cancelRequest}
        onRequestClose={() => setShowCancelDialog(false)}
        isOpen={showCancelDialog}
      />
      <Helmet>
        <title>Trip Details | TripID: {deliveryId}</title>
        <meta name="description" content="Trippie Air | Authorise new trips" />
      </Helmet>
      <div className="trip-details-container">
        {deliveryData?.status !== REQUEST_CANCELLED &&
          deliveryData?.status !== REQUEST_FINALISED &&
          deliveryData?.status !== REQUEST_DELIVERED &&
          deliveryData?.status !== REQUEST_COMPLETE && (
            <div className="assign-drive">
              <h1 className="control-title">Assign a Driver</h1>
              <div>
                <div className="select-drive">
                  {showSelectBoxStatuses.includes(deliveryData?.status) && (
                    <div className="select-drive">
                      <Select
                        value={selectedOption}
                        isSearchable={true}
                        isClearable={true}
                        onChange={setSelectedOption}
                        options={driveOptions}
                      />
                      {deliveryData?.status !== 2 ? (
                        <button
                          className="user-action-button switch-green save-user-btn
                          "
                          onClick={switchDriverHandler}
                        >
                          Switch Driver
                        </button>
                      ) : (
                        <button
                          className={`user-action-button switch-green save-user-btn`}
                          disabled={
                            deliveryData?.status === 5 ||
                            deliveryData?.status === 6
                          }
                          onClick={assignDriverHandler}
                        >
                          Assign Driver
                        </button>
                      )}
                    </div>
                  )}

                  <button
                    style={{ backgroundColor: "#ef1126" }}
                    className={`user-action-button switch-green save-user-btn 
                      }`}
                    onClick={() => setShowCancelDialog(true)}
                  >
                    Cancel Request
                  </button>
                </div>
              </div>
            </div>
          )}

        <div className="data-container">
          <div className="box-title-container">
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}></div>
          </div>
          <div className="data-row">
            <div className="trip-data">
              <div className="delivery-info " id="tripFlightDetails">
                <div className="details-title">
                  <div className="title-content">Delivery Request Details</div>
                </div>
                <div className="content-card" style={{ display: "flex" }}>
                  <div className="info-content bold-title">Id</div>
                  <div className="info-content">{deliveryId}</div>
                </div>
                <div className="content-card" style={{ display: "flex" }}>
                  <div className="info-content bold-title">Item Name</div>
                  <div className="info-content">{deliveryData?.itemName}</div>
                </div>
                <div className="content-card" style={{ display: "flex" }}>
                  <div className="info-content bold-title">Payment Type</div>
                  <div className="info-content">
                    {deliveryData?.paymentType.toUpperCase()}
                  </div>
                </div>
                <div className="content-card" style={{ display: "flex" }}>
                  <div className="info-content bold-title">Payment Done</div>
                  <div className="info-content">
                    {deliveryData?.paymentSuccess === 1 ? "Success" : "Pending"}
                  </div>
                </div>
                <div className="content-card" style={{ display: "flex" }}>
                  <div className="info-content bold-title">Payment Amount</div>
                  <div className="info-content">
                    <b>{formattedDeliveryAmount(deliveryData)}</b>
                  </div>
                </div>
                <div className="content-card" style={{ display: "flex" }}>
                  <div className="info-content bold-title">Status</div>
                  <div className="info-content">
                    <b>{getStatus(deliveryData?.status)}</b>
                  </div>
                </div>
                {isCancelled(deliveryData?.status) && (
                  <div className="content-card" style={{ display: "flex" }}>
                    <div className="info-content bold-title">
                      Cancellation Reason
                    </div>
                    <div className="info-content">
                      <b>{deliveryData?.cancellationReason}</b>
                    </div>
                  </div>
                )}
                <div className="content-card" style={{ display: "flex" }}>
                  <div className="info-content bold-title">Pickup Date</div>
                  <div className="info-content">
                    {deliveryData?.pickUpDateTime == null ? (
                      <div className="flexible-delivery">
                        <span>
                          <b>This delivery is Flexible</b>
                        </span>
                      </div>
                    ) : (
                      <b>
                        {deliveryData.pickUpDateTime
                          .toDate()
                          .toLocaleString("en-NZ")}
                      </b>
                    )}
                  </div>
                </div>
              </div>
              <div id="tripFlightDetails">
                <div className="depart-arrive-details">
                  <div className="depart-details">
                    <div className="details-img">
                      <FaTruckPickup size={50} color="#01a2cc" />
                    </div>
                    <div className="info" style={{ display: "block" }}>
                      <div className="info-content bold-title">
                        Pickup Address
                      </div>
                      <div className="info-content">
                        {`${deliveryData?.pickUpAddress.street},${deliveryData?.pickUpAddress.city},${deliveryData?.pickUpAddress.postcode}`}
                      </div>
                      <div className="info-content">
                        {deliveryData?.pickUpDateTime
                          ?.toDate()
                          .toLocaleString("en-NZ")}
                      </div>
                    </div>
                  </div>

                  <div className="arrive-details">
                    <div className="details-img">
                      <TbTruckDelivery size={50} color="#01a2cc" />
                    </div>
                    <div className="info" style={{ display: "block" }}>
                      <div className="info-content bold-title">
                        Dropoff Address
                      </div>
                      <div className="info-content">
                        {deliveryData?.tripData?.arrivalAirport}
                        {`${deliveryData?.dropOffAddress.street},${deliveryData?.dropOffAddress.city},${deliveryData?.dropOffAddress.postcode}`}
                      </div>
                      <div className="info-content">
                        {deliveryData?.dropDateAndTime
                          ?.toDate()
                          .toLocaleString("en-NZ")}
                      </div>
                    </div>
                  </div>
                  {deliveryData?.distance && (
                    <div className="distance">
                      Distance: {Number(deliveryData?.distance ?? 0).toFixed(2)}{" "}
                      KM
                    </div>
                  )}
                </div>
              </div>
              <div id="tripBagDetails">
                <div className="traveller-details-container">
                  <div className="details-title">
                    <div className="title-content">Details</div>
                  </div>
                  <div className="baggage-details">
                    <div className="baggage-details-header">
                      <div className="header-bag-type details-img">
                        <i className="fas fa-suitcase"></i>
                        <div>Type of delivery</div>
                      </div>
                      <div className="header-bag-dimensions details-img">
                        <i className="fas fa-ruler-combined"></i>
                        <div> Dimensions</div>
                      </div>
                      <div className="header-available-weight details-img">
                        <i className="fas fa-dumbbell"></i>
                        <div> Weight</div>
                      </div>
                    </div>
                    <div className="baggage-details-header content">
                      <div className="header-bag-type details-img">
                        <div
                          className={`${
                            deliveryData?.isUrgent === "true"
                              ? "cancellation-reason"
                              : ""
                          }`}
                        >
                          {deliveryData?.isUrgent === "true" ? "U" : "R"}
                        </div>
                      </div>
                      <div className="header-bag-dimensions details-img">
                        <div>Height: {deliveryData?.itemHeight}</div>
                        <div>Width: {deliveryData?.itemWidth}</div>
                        <div>Depth: {deliveryData?.itemDepth}</div>
                      </div>
                      <div className="header-available-weight details-img">
                        <div>{deliveryData?.itemWeight}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tripTravellerDetails">
                <div className="traveller-details-container">
                  <div className="details-title" style={{ marginBottom: 10 }}>
                    <div className="title-content">Receiver Details</div>
                  </div>

                  <div className="delivery-info ">
                    <div className="content-card" style={{ display: "flex" }}>
                      <div className="info-content bold-title">
                        Receiver Name
                      </div>
                      <div className="info-content">
                        <b>{deliveryData?.itemReceiverName}</b>
                      </div>
                    </div>
                    <div className="content-card" style={{ display: "flex" }}>
                      <div className="info-content bold-title">
                        Receiver Phone
                      </div>
                      <div className="info-content">
                        <b>{deliveryData?.itemReceiverNo}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tripTravellerDetails">
                <div className="traveller-details-container">
                  <div className="details-title">
                    <div className="title-content">Customer Details</div>
                  </div>
                  <div
                    className="traveller-details"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/AccountDetails/${deliveryData?.senderId}`);
                    }}
                  >
                    <div className="details-img">
                      {deliveryData?.senderDisplayImg ? (
                        <img
                          className="user-profile-image"
                          src={deliveryData?.senderDisplayImg}
                          alt=""
                        />
                      ) : (
                        <i className="far fa-user-circle"></i>
                      )}
                    </div>
                    <div className="info">
                      <div className="info-content bold-title">
                        {deliveryData?.senderDisplayName}
                      </div>

                      <div className="info-content">
                        Listed{" "}
                        {deliveryData?.creationDate
                          ?.toDate()
                          ?.toLocaleString("en-NZ")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tripTravellerDetails">
                <div className="traveller-details-container">
                  <div className="details-title">
                    <div className="title-content">Driver Details</div>
                  </div>
                  {deliveryData?.history?.map((delivery) => (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/AccountDetails/${delivery?.travellerId}`);
                      }}
                      className="traveller-details"
                      key={delivery.travellerProfileImgUrl}
                    >
                      <div className="details-img">
                        {delivery?.travellerProfileImgUrl ? (
                          <img
                            className="user-profile-image"
                            src={delivery?.travellerProfileImgUrl}
                            alt="user-profile"
                          />
                        ) : (
                          <i className="far fa-user-circle"></i>
                        )}
                      </div>
                      <div className="info">
                        <div className="info-content bold-title">
                          {delivery?.travellerDisplayName}
                        </div>

                        <div className="content-wrap">
                          <div className="info-content">
                            Listed{" "}
                            {delivery?.date?.toDate().toLocaleString("en-NZ")}
                          </div>
                          <div className="info-content">
                            {getStatus(delivery?.assignedWork)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* delivery status history */}
              <div id="tripTravellerDetails">
                <div className="traveller-details-container">
                  <div className="details-title">
                    <div className="title-content" style={{ marginBottom: 10 }}>
                      Delivery History
                    </div>
                  </div>
                  {history?.map((delivery) => (
                    <div
                      className="traveller-details"
                      key={delivery.travellerProfileImgUrl}
                    >
                      <div className="info">
                        <div className="content-wrap">
                          <div className="info-content">
                            {delivery?.timestamp
                              ?.toDate()
                              ?.toLocaleString("en-NZ")}
                          </div>
                          <div className="info-content">
                            {getStatus(delivery?.status)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-trip-data-image">
              <div id="tripItineraryDetails">
                <img src={deliveryData?.imageUri} alt="parcel" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetails;
